<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>代理中心</el-breadcrumb-item>
                    <el-breadcrumb-item>代理管理</el-breadcrumb-item>
                    <el-breadcrumb-item>区代理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button type="primary" @click="add" icon="el-icon-plus">新增</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="agentName" label="代理名称"></el-table-column>
                    <el-table-column prop="operationArea" label="展业区域"></el-table-column>
                    <el-table-column prop="parentAgent" label="所属市"></el-table-column>
                    <el-table-column prop="userName" label="用户名称"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="totalMerchant" label="开通商户"></el-table-column>
                    <el-table-column prop="applyDate" label="开通时间"></el-table-column>
                    <el-table-column label="状态" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.state === 1">已开通</el-tag>
                            <el-tag type="info" v-else-if="scope.row.state === 0">未开通</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.state === 2" disabled>禁用</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button type="info" v-if="scope.row.state === 0" icon="el-icon-tickets" plain
                                       @click="power(scope.row)">
                                开通
                            </el-button>
                            <el-button type="danger" v-if="scope.row.state === 1" icon="el-icon-tickets" plain
                                       @click="power(scope.row)">
                                禁用
                            </el-button>
                            <el-button type="success" v-if="scope.row.state === 2" icon="el-icon-tickets" plain
                                       @click="power(scope.row)">
                                启用
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        list as api_list,
        close as api_close,
    } from "@/api/modules/operationsCenter/area";
    export default {
        components: {},
        props: {},
        data() {
            return {
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectId: "",
                where: {
                    pageNum: 1,
                    pageSize: 20,
                },
            };
        },
        methods: {
            async listData() {
                this.loading = true;
                const {code, data} = await api_list(this.where);
                if(code === 1){
                    this.totalData = data.count;
                    this.list = data.data;
                    this.loading = false;
                }
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.where.pageNum = this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.where.pageSize = this.pageSize = e;
                this.listData();
            },
            getDialog(val) {
                this.dialogEditVisible = val;
            },
            add() {
                this.$router.push({name: "agentProviceAdd"});
            },
            // eslint-disable-next-line no-unused-vars
            power(item, state) {
                if (item.state === 1 || item.state === 2) {
                    this.$confirm("确认操作?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.close(item.id);
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消",
                            });
                        });
                } else {
                    this.$router.push({
                        name: "agentProviceAdd", query: {
                            id: item.id,
                            phone: item.phone,
                            userName: item.userName,
                            address: item.address,
                            agentName: item.agentName
                        }
                    });
                }

            },
            /**
             * 禁用
             * @param id
             * @returns {Promise<void>}
             */
            async close(id) {
                // eslint-disable-next-line no-unused-vars
                const {code, data,msg} = await api_close(id);
                if (code === 1) {
                    await this.listData();
                    this.$message({type: "success",message: "成功!",});
                } else {
                    this.$message({type: "error",message: msg,});
                }
            },
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    /deep/ .el-input-number.is-controls-right .el-input__inner {
        padding-left: 2px;
        padding-right: 32px;
    }
</style>
